'use client';

import { useEffect, useRef, useState } from 'react';
import { EventProperties } from '@segment/analytics-next';
import { usePathname, useSearchParams } from 'next/navigation';
import { analytics, analyticsBrowser } from '@/analytics/segment';
import { toAnalyticsProps } from '@/app/(authenticated)/(marketplace)/preferences/_components/sendPreferencesUpdatedEvent';
import { useGhostStore } from '@/legacy/store';
import { AnalyticsEventNames } from './Event.types';

interface Props {
  segmentClientId: string;
}

const APPLICATION = 'marketplace';

const getCommonMetadata = (): EventProperties => ({
  width: window.innerWidth,
  height: window.innerHeight,
  screen_width: screen.width,
  screen_height: screen.height,
  application: APPLICATION,
});

/* Component that initializes our Segment account so we can track analytics events & pageviews. */
export const SegmentInit = ({ segmentClientId }: Props) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [hasInitialized, setHasInitialized] = useState(false);

  const buyerPreferencesRef = useRef<EventProperties>({});
  const { buyerPreferences } = useGhostStore();

  // when buyerPreferences change, update buyerPreferencesRef
  // this minimizes how often we pull from useGhostStore and run it through toAnalyticsProps
  // while not re-running the main useEffect every time buyerPreferences changes
  useEffect(() => {
    if (buyerPreferences) {
      buyerPreferencesRef.current = toAnalyticsProps(buyerPreferences);
    } else {
      buyerPreferencesRef.current = {};
    }
  }, [buyerPreferences]);

  useEffect(() => {
    if (!segmentClientId) return;

    analyticsBrowser.addSourceMiddleware(({ payload, next }) => {
      const payloadType = payload.type();
      if (payloadType === 'track' || 'page') {
        const newProps = {
          ...payload.obj.properties,
          ...getCommonMetadata(),
          ...buyerPreferencesRef.current,
        };
        payload.obj.properties = newProps;
      }
      return next(payload);
    });

    analyticsBrowser.load({ writeKey: segmentClientId });

    setHasInitialized(true);
    analytics.track(AnalyticsEventNames.SEGMENT_ANALYTICS_INITIALIZED);
  }, [segmentClientId]);

  useEffect(() => {
    // Fire page events whenever url path or params update
    if (hasInitialized) {
      analytics.page();
    }
  }, [pathname, searchParams, hasInitialized]);

  return null;
};
